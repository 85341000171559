#home{
  position: relative;
}

.app__container{
  padding-top: 0;
}
.break {
  flex-basis: 100%;
  height: 0;
  width: 0;
}
.app__header{
  flex: 1;
    width: 100%;
    display: flex;
  flex-wrap: wrap;
    flex-direction: row;
  justify-content: center;
    padding: 2rem 4rem 0;

  @media screen and (min-width: 2000px) {
    padding-top: 4rem;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
    @media screen and (max-width: 450px) {
        padding: 0 1rem 2rem;
    }

}
.app__header-info{
  flex: .4;
  display: flex;
  flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  align-content: flex-end;
  height: 100%;

  @media screen and (max-width: 2000px) {
    width: 100%;
    margin-right: 0;
  }

  @media screen and (max-width: 900px) {
    margin-right: 0;
  }

}

.app__header-badge{
  width: 100%;
  display: flex;
  flex-direction: column;
    align-items: flex-start;

  .badge-cmp, tag-cmp{

flex-direction: column;
    align-items: flex-start;

  }    @media screen and (max-width: 550px) {
  align-items: center;
  justify-content: center;
}
}
.app__header-img{
  display: flex;
  flex: .4;
  align-content: flex-end;
    justify-content: center;
    max-width: 450px;
  img{
    width: 50%;
    height: auto;
    object-fit: cover;
  }
    @media screen and (max-width: 900px) {
        margin-top: 2rem;
      width: 100%;
      align-content: flex-start;
      align-self: flex-start;
        justify-content: flex-start;

    }
  @media screen and (max-width: 550px) {
    margin-top: 3rem;
    align-content: center;
    justify-content: center;

  }
}

.app__header-desc{

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}
.my-name{
  font-size: 5rem;
 line-height: 5rem;

  font-weight: 1000;
  color: var(--primary-color);
  margin-top: 1rem;
  margin-bottom: -1px;
padding: 0;
  text-transform: uppercase;
  font-family: "Century Gothic", serif;

  @media screen and (min-width: 2000px) {
    font-size: 7rem;
    line-height: 7rem;
  }
  @media screen and (max-width: 550px) {
    font-size: 3rem;
    line-height: 3rem;
    margin-top: 0;
  }


}
.my-desc{
  position: unset;
top: 5rem;
}
.app__hex{
  --s : 350px;
  width: var(--s);
  margin: var(--m);
  height: calc(var(--s) * 1.1547);
  display: flex;
    justify-content: center;
    align-items: center;
  font-size: 20px; /* we reset the font-size if we want to add some content */
  margin-bottom: calc(var(--m) - var(--s) * 0.2886); /* some negative margin to create overlap */
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  background-color: #ffffff10;
  span {
    font-size: 60px;

  }

}
.greeting{
    font-size: 1.8rem;
  height: 2rem;
    font-weight: 500;
    text-align: left;
    font-family: monospace;
    color: var(--lightBlue-color);




    @media screen and (max-width: 550px) {
        font-size: 1.2rem;
        }
}
