.app__navbar {
  z-index: 1;
  height: 64px;
  width: 100%;
  max-width: 1400px;
border-bottom: 1px solid linear-gradient(20deg, var(--primary-color), var(--secondary-color) ) ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  padding: 1rem 2rem;
&:after {
     content: '';
  position: absolute;
  top: 64px;
    left: 0;


  width: 100%;
  height: 1px;

  background: linear-gradient(to right, rgba(255,255,255,0) 5% , var(--secondary-color)20% , var(--secondary-color)80% , rgba(255,255,255,0) 95%) ;
}

}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 46px;
    height: auto;
  }

}

.app__navbar-links{
flex: 1;
  max-width: 1200px;
  margin: auto;
  display: flex;
    justify-content: flex-end;
    align-items: center;

    list-style: none;

  li{
    margin: 0 1rem;
    flex-direction: column;
    cursor: pointer;
    font-size: medium;


    div {
      width: 5px;
        height: 5px;
        background-color: transparent;
      border-radius: 50%    ;

      margin-bottom: 5px;
    }

    a {
      text-decoration: none;
      color: var(--primary-color);
      flex-direction: column;
      text-transform: uppercase;
      font-weight: 700;
        font-size: 1.1rem;




    }
    &:hover {

      a{

          color: var(--lightBlue-color);

      }
    }

  }
  @media screen and (max-width: 900px) {
    display: none;
  }

}
.app__navbar-menu {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;



  div {
   position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    padding: 1rem;
    width: 70%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    background-color: #00000040;
    backdrop-filter: blur(15px);

    svg {


      color: var(--primary-color);

    }
    ul {
      list-style: none;
      margin: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      li {
        margin: 1rem;

        a {
          color: var(--lightBlue-color);
          text-decoration: none;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 500;

          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }


}
button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
    z-index: 10;


}
.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  background: #ffffff55;

  ul {
    padding-top: 50px;
  }
}