.app__skills{

  display: flex;
  flex-direction: column;
max-width: 900px;
  padding-bottom: calc(var(--s)*3);
}

.app__skills-container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, calc(var(--s) + 2*var(--m)));
  justify-content:center;


  --s: 120px;  /* size  */
  --m: 10px;
  --f: calc(var(--s) * 1.732 + 4 * var(--m) - 1px);

    @media screen and (max-width: 550px) {
    --s: 23vw;
      --m: 5px;/* size  */
    }
}

.app__skills-item {
  position: relative;
  width: var(--s);
  margin: var(--m);
  height: calc(var(--s) * 1.1547);
  display: inline-block;
  font-size: 20px; /* we reset the font-size if we want to add some content */
  margin-bottom: calc(var(--m) - var(--s) * 0.2886); /* some negative margin to create overlap */
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);

  &:hover {

      transform: scale(1.1);

  }
  &:last-child {

  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
position: absolute;
   width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #ffffff10 0%,  #ffffff30 100%);

    .p-text{
      margin: 5px auto ;


      font-size: medium;
      font-weight: 500;

      color: #A0BDD4;
      @media screen and (max-width: 450px) {
        font-size: small;
      }
    }


  img {
    position: relative;
    width: 40%;
    height: auto;


  }
  }
}
.app__skills-list{
  font-size: 0;
  grid-column: 1/-1;
  margin-bottom: var(--s)+5rem;

  @media screen and (max-width: 650px) {
    height: calc(var(--s) *5);

  }

  &::before {
    content: "";
    width: calc(var(--s) / 2 + var(--m));
    float: left;
    height: 100%;
    shape-outside: repeating-linear-gradient(#0000 0 calc(var(--f) - 3px), #000 0 var(--f));
  }
}
