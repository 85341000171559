.app__projects{
    width: 100%;
margin-top: 2rem;
    display: flex;

    flex-wrap: wrap;
    justify-content: center;
gap: 2rem;
    align-content: flex-start;


    @media screen and (min-width: 2000px) {
        padding-top: 4rem;
    }
    @media screen and (max-width: 900px) {


    }
    @media screen and (max-width: 450px) {
        padding: 0 0rem 2rem;
      width: 100vw;
    }
}
.app__projects-item{


width: 100%;
    margin: 1rem;
    padding: 2rem;

  border-radius: 10px;
  max-width: 600px;
  background: linear-gradient(to bottom, #ffffff30, #ffffff10);
    @media screen and (min-width: 2000px) {
        width: 100%;

    }
    @media screen and (max-width: 450px) {
       width: 100%;

    }

}
.app__projects-text{
  color: var(--color-white);
  p{
    color: var(--lightBlue-color);
  }
}
.app__projects-links{
  width: 100%;
  margin: 3rem  0;
    display: flex;
    align-items: center;
justify-content: space-evenly;
  height: auto;


    @media screen and (max-width: 550px) {
      justify-content: space-between;

    }


}
.app__projects-link{
  border: 2px solid var(--secondary-color);
  border-radius: 30px;
  background-color:var(--secondary-color) ;
  color: var(--black-color);
  text-decoration: none;
  text-align: center;
  font-size: 1.2rem;
padding: 5px;
    width: 30%;
  &:hover{
    color: var(--primary-color);
  }
  @media screen and (max-width: 550px) {
      width: 40%;

  }
}
.app__projects-link-2{
  border: 2px solid var(--secondary-color);
  border-radius: 30px;


  color: var(--lightBlue-color);
  text-decoration: none;
  text-align: center;
  font-size: 1.2rem;
  padding: 5px;
  width: 30%;
  &:hover{
    color: var(--primary-color);
  }
  @media screen and (max-width: 550px) {
    width: 40%;

  }
}
.app__projects-media{
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  margin: 0;
    margin-top: 0rem;
    @media screen and (min-width: 2000px) {
        width: 100%;
    }
    @media screen and (max-width: 900px) {

    }
  &:hover{


  }
}
.app__projects-image{
    width: 100%;
    height:auto;
    @media screen and (min-width: 2000px) {
        width: 100%;
    }
    @media screen and (max-width: 900px) {

    }
}
.app__projects-video-wrapper{
  position: relative;
    width: 100%;
  height: 0;

    padding-bottom: 56.25%;


}
.app__projects-video{

    width: 100%;
position: absolute;
  height: 100%;
    border: none;
    @media screen and (min-width: 2000px) {
        width: 100%;

    }
    @media screen and (max-width: 900px) {

    }
     @media screen and (max-width: 450px) {
        width: 100%;

       
     }
}