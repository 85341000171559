.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.app__footer-cards {
  width: 60%;
  display: flex;

  margin: 4em 2rem 2rem;

  .app__footer-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fef4f5;

    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 500;
    }
    a {
      text-decoration: none;
      font-weight: 500;
    }


    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app__footer-cards .app__footer-card:last-child {
  background-color: #f2f7fb;
}

.app__footer-cards .app__footer-card:last-child:hover {
  box-shadow: 0 0 25px #f2f7fb;
}

.app__footer-form {
  width: 60%;
  flex-direction: column;
  margin: 1rem 2rem;

  div {
    width: 100%;
position: relative;
    margin: 0.75rem 0;
    border-radius: 10px;
    display: block;

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: #222c35;

      font-family: var(--font-base);
      color: var(--lightBlue-color);
      outline: none;

      &:hover {
        outline: var(--primary-color) 1px solid;
      }
    }
    .error{
      outline: #e06279 1px solid;
      &:after{
        content: "rrrrrrrr";
        color: #e06279;
        font-size: 0.8rem;
        position: absolute;

      }

    }
    textarea {
      height: 170px;
    }
    .error-text{
      color: #e06279;
      font-size: 0.9rem;
      position: absolute;
      display: inline-flex;
      height: 0;
      width: 10rem;
top: -1.1rem;
        left: 1rem;

    }

  }

  button {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: none;
    background-color: var(--secondary-color);
width: fit-content;
    font-weight: 500;
    display: flex;
    align-content: center;
    justify-content: center;
    color: #000;
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: var(--primary-color);
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}
.app__footer-copyright{
  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
    flex-direction: row;
margin: 80px;

margin-bottom: -20px;

}