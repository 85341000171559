.app__games{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;

}

.app__games-item{
  width: 250px;
  padding: 1rem 1rem ;
    height: 360px;
  display: flex;
  justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 2rem;
  background-color: #ffffff10;
    border-radius: 10px;
  background: linear-gradient(180deg, #ffffff20 0%, #ffffff09 100%);

  img{
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;
  }
&:hover {
  background-color: #ffffff20;
  cursor: pointer;
  scale: 1.02;
}
}
h3{
  font-size: 1.6rem;
  color: var(--lightBlue-color);
  font-weight: 600;
  margin: 1rem 0;
  text-align: left;
}
