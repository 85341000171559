@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #279dfe;
  --secondary-color: #ff7d2e;
  --black-color: #030303;
  --darkGray-color: #232323;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
  --lightBlue-color: #e4f3ff;
    --darkBlue-color: #081621;
  --darkBg-color: #1C1C1C;
    --darkBlue2-color: #001629;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}